import SettleIntop from '@/components/settleIntop'
import SettleInFoot from '@/components/settleInFoot'
const Index = {
  name: 'RetrieveNumber',
  components: {
    SettleInFoot,
    SettleIntop
  },
  mounted() {
    this.GetValidateCodeImg()
  },
  data() {
    return {
      code_img: '',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }
    }
  },
  methods: {
    next() {
      this.$router.push({
        name: 'NumberBack'
      })
    },
    changeImg() {
      this.GetValidateCodeImg()
    },
    GetValidateCodeImg() {
      this.$api.GetValidateCodeImg().then(res => {
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        this.code_img = `data: image/jpg; base64, ${res.Message.Bytes}`
        // this.form.Guid = res.Message.Guid
      })
    }
  }
}
export default Index

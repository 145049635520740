<template>
  <div class="settlein">
    <SettleIntop />
    <div class="main_set">
      <div class="main m-top-20">
        <div class="userinfo_header flex column " style="height:400px">
          <div class="header p-lr-20 flex j-between a-center">
            <div class="weight">修改企业超级管理员账号</div>
          </div>
          <div class="main_a flex j-center a-center">
            <div class="main_b ">

              <el-form ref="form" :model="form" label-width="150px">
                <el-form-item label="公司名称：">
                  <el-input v-model="form.name" style="width:50%" size="mini" />
                </el-form-item>
                <el-form-item label="社会统一信用代码：">
                  <el-input v-model="form.name" style="width:50%" size="mini" />
                </el-form-item>
                <el-form-item label="图片校验码：">
                  <div class="flex">
                    <el-input v-model="form.name" style=" width: 50%;" size="mini" class="form_settlein" />
                    <div v-if="code_img !==null && code_img.length > 0" class="m-top-5 cursor m-left-20">
                      <img class="code_img cursor" :src="code_img" @click="changeImg">
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="flex">
                    <div class="btn f12" @click="next">下一步</div>
                    <div class="btn_back f12 m-left-20 p-lr-20 theme-color weight">若忘记账号点击这里</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SettleInFoot />
  </div>
</template>
<script>
import Index from '@/minxin/settlein/RetrieveNumber'
export default Index
</script>
<style lang="scss" scoped>
@import '../../style/settlein.scss';
.main_a {
    width: 100%;
    height: 100%;

    .main_b {
        width: 50%;
        height: 200px;
        margin: 0 auto;
        .btn {
            width: 120px;
            height: 30px;
            background-color: #3e76ed;
            border-radius: 50px;
            color: #fff;
            line-height: 30px;
            text-align: center;
       }
       .btn_back {
            height: 30px;
            background-color: #ccc;
            border-radius: 50px;
            color: #fff;
            line-height: 30px;
            text-align: center;
            color: #006fda;
       }
    }
}
</style>
